body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  text-align: center;
}

.yellow-text {
  color: #e3bd57;
  stroke: #e3bd57;
}
.primary-bgc {
  background-color: #752757 !important;

  border-color: transparent !important;
}
.thrifty-text {
  color: #752757;
}
.disclosure-text {
  font-size: 10px;
}

.description-text {
  font-size: 16px;
  color: #808080;
}

.mobile-shop-button{
  height: auto;
  font-size: 2.7vmin;
  white-space: nowrap;
}
.product-title-text {
  font-size: 35px;
  line-height: 1.1;
}

.price-text {
  font-size: 25px;
}
.upvote-button {
  background-color: #e4e9ec !important;
  border-color: transparent !important;
  width: 75%;
  font-size: 15px;
}

.item {
  position:relative;
  padding-top:20px;
  display:inline-block;
}
.deal-badge-left{
  position: absolute;
  left: 20px;
  top:10px;
  background: #752757 !important;
  text-align: center;
  border-radius: 30px 30px 30px 30px;
  color:white;
  padding:5px 10px;
  font-size:10px !important;
}

.deal-badge-heart-gray{
  position: absolute;
  right: 20px;
  top:5px;
  background: transparent !important;
  text-align: center;
  border-radius: 30px 30px 30px 30px;
  color:  lightgray !important;
  padding:5px 10px;
  font-size:20px !important;
}

.deal-badge-heart-red{
  position: absolute;
  right: 20px;
  top:5px;
  background: transparent !important;
  text-align: center;
  border-radius: 30px 30px 30px 30px;
  color:  red !important;
  padding:5px 10px;
  font-size:20px !important;
}


.box {
  margin: 5px;
}

.grid {
   /*min-height: 100%;*/
  display: flex;
  flex-wrap: wrap ;
  flex-direction: row;
  padding: 10px;


}
@media all  {
  .grid > div {
    flex-basis: calc(25% - 10px);
  }
}

@media only screen and (max-width : 480px) {
  .grid > div {

    /*flex-basis: calc(50% - 10px);*/
flex-basis: calc(100%);
    padding: -10px;
}
}

.black-background {
background-color: red !important;
font-size: 5vh;
border-radius: 10px 10px 10px 10px;
}


input[type='range'] {
  width: 100%;
}


/*Chrome*/

@media screen and (-webkit-min-device-pixel-ratio:0) {
  input[type='range'] {
    overflow: hidden;
    -webkit-appearance: none;
    background-color: lightgray;

  }
  input[type='range']::-webkit-slider-runnable-track {
    height: 20px;
    -webkit-appearance: none;
    color: lightgray;
    margin-top: -1px;
  }
  input[type='range']::-webkit-slider-thumb {
    width: 60px;
    -webkit-appearance: none;
    height: 30px;
    cursor: cell;
    background: #6c757d;


  }
}


/** FF*/

input[type="range"]::-moz-range-progress {
  background-color: lightgray;
}

input[type="range"]::-moz-range-track {
  background-color: #6c757d;
}


/* IE*/

input[type="range"]::-ms-fill-lower {
  background-color: lightgray;
}

input[type="range"]::-ms-fill-upper {
  background-color: #6c757d;
}
